@font-face {
    font-family: 'Optima';
    src: url('../fonts/Optima-Regular.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Optima-Regular.woff') format('woff'),
        url('../fonts/Optima-Regular.ttf')  format('truetype'),
        url('../fonts/Optima-Regular.svg#Optima-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}
@font-face {
    font-family: 'Optima';
    src: url('../fonts/Optima-Bold.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Optima-Bold.woff') format('woff'),
        url('../fonts/Optima-Bold.ttf')  format('truetype'),
        url('../fonts/Optima-Bold.svg#Optima-Bold') format('svg');
    font-weight: bold;
    font-style: normal;
}
::-webkit-scrollbar {
    width: 5px;
}
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #ccc;
    border-radius: 10px;
}
::-webkit-scrollbar-thumb {
    background: #6c757d; 
    border-radius: 10px;
}
::-webkit-scrollbar-thumb:hover {
    background: #6c757d; 
}
::selection {
    color: #fff;
    background-color: #003474;
}
body {
    font-family: 'Optima', sans-serif;
    font-size: 1.2rem;
    line-height: 1.5;
    color: #003474;
}
.back_home {
    position: relative;
    z-index: 777;
}
.mt-10 {
    margin-top: 6rem;
}
.mt-15 {
    margin-top: 15rem;
}
.desc_imperatrice {
    padding-top: 20vw !important;
}
.mt-20 {
    margin-top: 20rem;
}
.mb-20 {
    margin-bottom: 20rem;
}
.mb-15 {
    margin-bottom: 12rem;
}
.logo {
    position: fixed;
    top: 2vh;
    left: 0;
}
a, a:hover, a:focus, a:active {
    color: #003474;
    text-decoration: none;
}
h1 {
    font-size: 2.5rem;
}
.cc-window {
    z-index: 99999 !important;
}
.home h1 {
    position: relative;
    z-index: 78;
}
.home {
    background-color: #f1f4fb;
}
h2 {
    font-size: 1.3rem;
    font-weight: bold;
}
h3 {
    font-size: 1.75rem;
}
h4 {
    font-size: 1.4rem;
}
.blanc {
    color: #fff;
}
.bleu {
    color: #003474;
}
.gris {
    color: #7d7d7d;
}
.dore {
    color: #ae8c3c;
}
.bg_beige {
    background-color: #f9f5e7;
}
.noir {
    color: #000;
}
.border-blanc {
    border-left: none;
}
.desc_imperatrice p {
    border-left: 2px solid #fff;
    padding-left: 1.5rem !important;
}
.imperatrice {
    background: url(../images/cuvees/bg_imperatrice.jpg) 50% 0% no-repeat;
    background-size: cover;
}
.burger, .close_btn {
    cursor: pointer;
    position: fixed;
    max-width: 55px;
    z-index: 777;
    right: 2em;
    top: 0;
    display: none;
}
.burger.on, .close_btn.on {
    display: block;
    z-index: 777777777;
}
.menu {
    opacity: 0;
    font-size: 1.5rem;
    background-color: #fff;
    color: #a1123b;
    position: fixed;
    z-index: -7;
    transition: all 500ms ease;
}
.menu ul {
    list-style: none;
    font-weight: bold;
    margin-block-start: 0;
    padding-inline-start: 0;
}
.menu ul ul {
    font-weight: normal;
    font-size: 1rem;
    font-style: italic;
    font-size: 1.2rem;
}
.menu.on {
    opacity: 1;
    z-index: 7777777;
}
.fleche-droite {
    max-width: 30px;
}
.scene_patrimoine {
    position: absolute;
    top: 0;
    z-index: -7;
    opacity: 0;
    transition: all 300ms ease;
}
.scene_patrimoine.on {
    opacity: 1;
}
#patrimoine5 {
    background: url(../images/patrimoine/1998/bg-1998.jpg) repeat-x 40% 100%;
}
#patrimoine6 {
    background: url(../images/patrimoine/2010/bg-2010.png) no-repeat 50% 100%;
}
#patrimoine7 {
    background: url(../images/patrimoine/2017/bg-2017.jpg) no-repeat 50% 100%;
    background-size: contain;
}
#patrimoine8 {
    background: url(../images/patrimoine/2018/bg-2018.jpg) no-repeat 50% 100%;
}
.position-abs {
    position: absolute;
    top: 0;
}
/* Verify age */
.verify {
    transition: all 700ms ease;
    background-color: #fff;
    z-index: 77777777777777777777;
    position: fixed;
    padding: 15px;
    opacity: 1;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;
}
.verify.off {
    opacity: 0;
    z-index: -7;
}
.verify img {
    max-width: 300px;
}
.verify p {
    max-width: 500px;
    margin: auto
}
.verify label {
    font-weight: bold;
    color: #003474;
}
.verify a {
    color: #003474
}
.verify h1 {
    color: #003474;
    font-weight: normal;
    text-shadow: none;
}
.btn.btn-verify {
    background-color: #003474;
    border-color: #003474;
    cursor: pointer;
    color: #fff !important;
    text-transform: uppercase;
    padding: .3em 2.5em;
    border-radius: 30px;
}
.btn-verify:hover, .btn-verify:active, .btn-verify:focus {
    border-color: #003474;
}
/* Fin Verify age */
.overlay {
    display: none;
    position: fixed;
    top: 0;
    background-color: #003474;
    width: 100%;
    height: 100%;
    overflow: hidden !important;
    z-index: 777777777 !important;
}
.iconContainer {
    width: 300px;
    height: 200px;
    position: absolute;
    left: 50%;
    top: 50%;
    margin-left: -150px;
    margin-top: -100px;
}
.iconContainer p {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin: 0;
    font-size: 20px;
    opacity: 0;
}
.phone {
    position: relative;
    left: 50%;
    margin-left: -80px;
    width: 0;
    height: 0;
    border: 0 solid #000;
    background: #a9a9a9;
    border-radius: 10px;
    box-sizing: border-box;
    margin-bottom:45px;
}
.phone i {
    text-align: center;
    width: 100%;
    line-height: 120px;
    font-size: 50px;
    color: #454545;
    opacity: 0;
}
@media only screen and (orientation: landscape) and (max-width: 600px) {
    .overlay {
        display: block;
        z-index:666;
    }

    .overlay .phone {
        -webkit-animation: sizeIncrease .5s forwards ease,  borderIncrease .5s .5s forwards ease,  rotateLeft .7s 1s forwards ease;
        animation: sizeIncrease .5s forwards ease,  borderIncrease .5s .5s forwards ease,  rotateLeft .7s 1s forwards ease;
    }

    .overlay .phone i {
        -webkit-animation: fadeIn .5s .8s forwards ease;
        animation: fadeIn .5s .8s forwards ease;
    }

    .overlay .iconContainer p {
        -webkit-animation: fadeIn .5s 1.3s forwards ease;
        animation: fadeIn .5s 1.3s forwards ease;
    }
}
@-webkit-keyframes sizeIncrease {
    0% {
        width: 10px;
        height: 0;
    }
    50% {
        width: 10px;
        height: 100px;
    }
    100% {
        width: 160px;
        height: 100px;
    }
}
@keyframes sizeIncrease {
    0% {
        width: 10px;
        height: 0;
    }
    50% {
        width: 10px;
        height: 100px;
    }
    100% {
        width: 160px;
        height: 100px;
    }
}
@-webkit-keyframes borderIncrease {
    100% {
        border-width: 10px 20px;
    }
}
@keyframes borderIncrease {
    100% {
        border-width: 10px 20px;
    }
}
@-webkit-keyframes fadeIn {
    100% {
        color: white;
        opacity: 1;
    }
}
@keyframes fadeIn {
    100% {
        color: white;
        opacity: 1;
    }
}
@-webkit-keyframes rotateLeft {
    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}
@keyframes rotateLeft {
    100% {
        -webkit-transform: rotate(-90deg);
        transform: rotate(-90deg);
    }
}
.btn-primary {
    background-image: none;
    background-color: #FED300;
    color: #003474;
    padding: .2em 1.5em;
    border-color: transparent;
    border-radius: 25px;
    font-weight: bold;
}
.btn-primary:focus, .btn-primary:active, .btn-primary:hover {
    background-color: #003474;
    color: #FED300;
}
.hide {
    opacity: 0;
    transition: all 400ms ease;
}
.customPrevBtn, .customNextBtn {
    width: 12px;
}
.customNextBtn, .customPrevBtn {
    cursor: pointer;
}
.owl-stage-outer:before {
    position: absolute;
    display: block;
    content: '';
    z-index: 1;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
}
.owl-stage-outer:before {
    /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#ffffff+0,ffffff+100&1+0,0+35,0+65,1+100 */
    background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 35%, rgba(255,255,255,0) 65%, rgba(255,255,255,1) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 65%,rgba(255,255,255,1) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 35%,rgba(255,255,255,0) 65%,rgba(255,255,255,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
}
.brevets {
    display: none;
    height: auto;
    max-width: 600px;
    position: absolute;
    left: 50%;
    margin-left: -300px;
    top: 16%;
    opacity: 0;
    z-index: -77;
    height: 0;
    width: 0;
}
.brevets.on {
    opacity: 1;
    z-index: 7;
    width: 100%;
    height: auto;
}
.scene {
    position: relative;
}
.scene1 {
    background: url(../images/home/bg_scene1.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.scene2 {
    background: url(../images/home/bg_scene2.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.scene3 {
    background: url(../images/home/bg_scene3.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.scene4 {
    background: url(../images/home/bg_scene4.jpg) 50% 50% no-repeat;
    background-size: cover;
}
.btls {
    position: fixed;
    z-index: 77;
}
.btl {
    position: absolute;
    bottom: 2%;
    width: 30vh;
    height: 0px;
    z-index: 77;
}
.btl2 {
    height: 80vh;
    z-index: 777;
}
.btl1 {
    background: url(../images/home/btl1.png) 50% 100% no-repeat;
    background-size: cover;
}
.btl2 {
    background: url(../images/home/btl2.png) 50% 100% no-repeat;
    background-size: cover;
}
.btl3 {
    background: url(../images/home/btl3.png) 50% 100% no-repeat;
    background-size: cover;
}
.btl4 {
    background: url(../images/home/btl4.png) 50% 100% no-repeat;
    background-size: cover;
}
.next, .prev {
    position: absolute;
    margin-top: -17.5px;
    z-index: 777;
    width: 35px;
    top: 50%;
}
.next {
    right: 15px;
}
.prev {
    left: 15px;
}
.form-control {
    border-radius: 0;
    border: none;
    border-bottom: 1px solid #003474;
    box-shadow: none !important;
}
textarea.form-control {
    height: 200px;
}
.btn-light {
    border: none !important;
    color: #003474;
    font-size: 1.1em;
    font-weight: bold;
    background-color: transparent !important;
    background-image: none !important;
    text-align: left;
    background: url(../images/fleche-droite.svg) 50% 10% no-repeat;
    background-size: 20px;
}
.btn-outline-primary {
    border: 1px solid #003474;
    color: #003474;
}
.btn-outline-primary:hover {
    background-color: #003474;
    border-color: #003474;
}
.illustrations {
    transition: all 400ms ease;
    position: absolute;
    display: none;
    left: 0;
    top: 0;
}
.illustrations.on {
    display: block;
}
.illus {
    transition: all 1100ms ease 500ms;
    position: absolute;
    max-height: 45vh;
    max-width: 14vw;
    opacity: 1;
}
.cuvee .illus {
    z-index: 7;
}
#carousel_cuvees:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

}
.illus:nth-child(1) {
    transition-delay: 500ms;
}
.illus:nth-child(2) {
    transition-delay: 700ms;
}
.illus:nth-child(3) {
    transition-delay: 900ms;
}
.illus:nth-child(4) {
    transition-delay: 1100ms;
}
#ligne2 .illus,
#ligne3 .illus,
#ligne4 .illus,
#ligne5 .illus,
#ligne6 .illus,
#ligne7 .illus,
#ligne8 .illus,
#ligne9 .illus {
    transition-delay: 200ms;
}
.illus-hg {
    left: -5vw;
    top: 1vh;
}
.illus-hg.off {
    left: -50vw;
    top: -50vh;
    opacity: 0;
}
.illus-hd {
    right: -5vw;
    top: 5vh;
}
.illus-hd.off {
    right: -50vw;
    top: -50vh;
    opacity: 0;
}
.illus-hm {
    left: 25vw;
    top: 2vh;
}
.floriage {
    left: -13vw;
    max-height: 75vh !important;
    max-width: 25vw !important;
    margin-top: 10vh;
}
.illus-hm-droite {
    right: 25vw;
    top: 2vh;
}
.illus-mg {
    top: 30vh;
    left: -5vw;
}
.illus-mg-deux {
    top: 25vh;
    left: -10vw;
}
.illus-mg.off {
    left: -50vw;
    top: -50vh;
    opacity: 0;
}
.illus-md {
    top: 30vh;
    right: -5vw;
}
.illus-md.off {
    right: -50vw;
    opacity: 0;
}
.illus-bg {
    left: -5vw;
    bottom: 1vh;
}
.illus-bg.off {
    left: -50vw;
    bottom: -50vh;
    opacity: 0;
}
.illus-bd {
    right: -5vw;
    bottom: 1vh;
}
.illus-bd.illus-triple-width {
    right: -18vw;
}
.illus-bd.off {
    right: -50vw;
    bottom: -50vh;
    opacity: 0;
}
.illus-bm {
    right: 25vw;
    bottom: 1vh;
}
.illus-bm-gauche {
    left: 25vw;
    bottom: 1vh;
}
.illus-bm-center {
    left: 45vw;
    bottom: 15vh;
}
.illus-on-mg {
    top: 40vh;
    left: 5vw;
}
.illus-on-mg.off {
    left: -50vw;
    opacity: 0;
}
.illus-on-md {
    top: 40vh;
    right: 5vw;
}
.illus-on-md.off {
    right: -50vw;
    opacity: 0;
}
.illus-on-hd {
    top: 1vh;
    right: 1vw;
}
.illus-on-hd.off {
    right: -50vw;
    top: -50vh;
    opacity: 0;
}
.illus-on-hg {
    top: 5vh;
    left: 5vw;
}
.illus-on-hg.off {
    left: -50vw;
    top: -50vh;
    opacity: 0;
}
.illus-on-bd {
    bottom: 10vh;
    right: 5vw;
}
.illus-on-bd.off {
    right: -50vw;
    bottom: -50vh;
    opacity: 0;
}
.illus-on-bg {
    bottom: 1vh;
    left: 1vw;
}
.illus-on-bg.off {
    left: -50vw;
    bottom: -50vh;
    opacity: 0;
}
.illus-un-et-demi-width {
    max-width: 25vw;
}
.illus-trois-quart-width {
    max-width: 27vw;
}
.illus-double-width {
    max-width: 31vw;
}
.illus-triple-width {
    max-width: 51vw;
    max-height: 60vh !important;
}
.illus-quadruple-width {
    max-width: 75vw;
    max-height: 90vh !important;
}
.illus-limit-height {
    max-height: 45vh;
}
.illus-full-b {
    bottom: 0;
}
.illus-full-t {
    top: 0;
}
.illus-full-r {
    right: 0;
}
.illus-full-l {
    left: 0;
}

.z-1 { z-index: 6000; }
.z-2 { z-index: 6001; }
.z-3 { z-index: 6002; }
.z-4 { z-index: 6003; }
.z-5 { z-index: 777777; }

.icones img {
    max-width: 60px;
    max-height: 60px;
    background-color: #003474;
    padding: 15px;
    border-radius: 60px;
    cursor: pointer;
    margin: 0 .5em;
    width: 60px;
    transition: all 700ms ease;
    height: 60px;
}
.icones img:last-child {
    margin-right: 0;
}
.icones img.on {
    background-color: #ae8c3c;
}
.notes_outer {
    position: relative;
}
.notes {
    position: absolute;
    opacity: 0;
    top: 0;
    left: -100px;
    transition: all 500ms ease;
}
.notes.on, .notes:not(.hide) {
    left: 0;
    opacity: 1;
}
.mw-200 {
    max-width: 200px;
}
.scroll-down {
    max-width: 30px;
    display: block;
    margin: 3em auto;
}
.scroll-down-fixed {
    max-width: 30px;
    position: fixed;
    bottom: 1em;
    z-index: 777;
    cursor: pointer;
    left: 50%;
    margin-left: -20px;
}
.scroll-down-abs {
    position: absolute;
    max-width: 30px;
    left: 50vw;
    bottom: 5vh;
    margin-left: -20px;
}
.verticaly-center {
    position: absolute;
    top: 50%;
    width: 99.2vw;
    transform: translateY(-50%);
}
.verticaly-center.header {
    top: 60%;
}
header .illus {
    max-width: 600px;
    width: 50vw;
    max-height: none;
}
.ico-plus {
    max-width: 30px;
    cursor: pointer;
    display: block;
    margin: 3em auto;
}
.btn-plus {
    width: 30px;
    padding: 14px;
    margin: 3em auto 0 auto;
    background: url(../images/ico-plus.svg) 50% 50% no-repeat;
}
.btn-plus.on {
    background: url(../images/ico-moins.svg) 50% 50% no-repeat;
}
.btn.focus, .btn:focus {
    box-shadow: none;
}
.paralaxx_art { 
    min-height: 550px;
    background: url(../images/art-du-champagne/paralaxx.jpg) 50% 0 no-repeat;
    background-size: cover;
}
.paralaxx_domaine { 
    min-height: 550px;
    background: url(../images/patrimoine/paralaxx-patrimoine.jpg) 50% 0 no-repeat;
    background-size: cover;
}
.block_orientation {
    display: none;
}
.left-border-jaune {
    border-left: 2px solid #fed300;
    padding-left: 3em;
}
.border-jaune {
    background-color: #fff;
    border: 2px solid #fed300;
    padding: 3em;
}
.relatif {
    position: relative;
}
.vagues2 {
    position: absolute;
    bottom: -15px;
    left: -65px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
    max-width: 500px;
    z-index: -7;
}
.carousel-control-prev-icon {
    background-image: url(../images/fleche-gauche-blanc.svg);
}
.carousel-control-next-icon {
    background-image: url(../images/fleche-droite-blanc.svg);
}
.carousel-control-next-icon, .carousel-control-prev-icon {
    width: 40px;
    height: 40px;
}
.carousel-control-next {
    justify-content: end;
    width: inherit;
    position: absolute;
    right: -20px;
}
.carousel-control-prev {
    justify-content: start;
    left: -20px;
}
.carousel-control-next:focus, .carousel-control-next:hover, .carousel-control-prev:focus, .carousel-control-prev:hover {
    opacity: 1;
}
.carousel-control-next, .carousel-control-prev {
    opacity: 1;
}
.mt-moins-5 {
    margin-top: -3rem!important;
}
.img-art {
    margin-top: -5em;
}
.overflow-hidden {
    overflow: hidden;
}
.bloc_jonkoping {
    margin-top: -10vh;
}
.bloc_jonkoping h1 {
    text-align: center;
    font-size: 5vw;
}
.bloc_jonkoping h1 small {
    font-size: 25px;
}
.date {
    position: relative;
    z-index: 7777;
    font-size: 5.5vw;
    font-weight: normal;
    margin-top: -2em;
    opacity: 0;
    transition: all 750ms ease;
}
.date.on {
    opacity: 1;
    margin-top: .5rem;
}
.resume {
    position: relative;
    z-index: 7777;
    font-size: 1.5rem;
    margin-top: -2em;
    opacity: 0;
    transition: all 750ms ease 200ms;
}
.resume.on {
    margin-top: 0;
    opacity: 1;
}
.desc {
    position: relative;
    z-index: 7777;
    opacity: 1;
    margin-top: 0;
    font-size: 1.1em;
    transition: all 750ms ease 500ms;
}
@media (max-width:600px) {
    .desc {
        max-height: 30vh;
        overflow-y: scroll;
    }
}
.desc.hide {
    margin-top: -2em;
    transition: all 200ms ease;
    opacity: 0;
}
.desc.on {
    margin-top: 0;
    opacity: 1;
}
.desc .btn-plus {
    display: block;
    margin: 2em auto;
}
.left-apparition, .right-apparition, .top-apparition, .bottom-apparition {
    opacity: 0;
    transition: all 1s ease-out
}
.left-apparition {
    transform: translateX(-50vw)
}
.left-apparition.on {
    transform: translateX(0);
    opacity: 1
}
.right-apparition {
    transform: translateX(50vw)
}
.right-apparition.on {
    transform: translateX(0);
    opacity: 1
}
.top-apparition {
    transform: translateY(-20vh)
}
.top-apparition.on {
    transform: translateY(0);
    opacity: 1
}
.bottom-apparition {
    transform: translateY(20vh)
}
.bottom-apparition.on {
    transform: translateY(0);
    opacity: 1
}
.font-fin {
    font-weight: 200 !important;
}
.abus {
    margin-top: 5vh;
    background-color: #efefef;
    position: relative;
    z-index: 77777;
    color: #000;
    font-size: .8em;
}
.mentions-legales {
    padding: 5vh 0;
}
.inner_content {
    flex: 1;
}
footer {
    background-color: #003474;
    color: #fff;
    position: relative;
    z-index: 77777;
}
footer ul {
    display: inline;
    list-style-type: none;
}
footer a {
    color: #fff !important;
}
.socials img {
    width: 36px;
    height: auto;
    margin: 0 .2em;
}
.triman {
    width: 55px;
}
.footer-logo {
    width: 125px !important;
    height: auto;
}
@media (max-width:768px) and (orientation: landscape) {
    .block_orientation {
        background-color: #ccc;
        color: #000;
        z-index: 777777777;
        display: block;
        padding: 1em 0;
        font-size: 1.2em;
        position: fixed;
        width: 100vw;
        height: 100vh;
        left: 0;
        text-align: center;
        top: 0;
    }
}
@media (max-height: 812px) {
    .illus-full-t.illus-on-hd {
        top: 55px;
    }
    #patrimoine5 {
        display: none;
    }
}
@media (max-height: 650px) {
    #patrimoine4 {
        display: none;
    }
    .mt-10 {
        margin-top: 3rem;
    }
    .mt-15 {
        margin-top: 5rem;
    }
    .mt-20 {
        margin-top: 10rem;
    }
    .mb-20 {
        margin-bottom: 10rem;
    }
    .mb-15 {
        margin-bottom: 6rem;
    }
}
@media (max-height: 750px) {
    .brevets.on {
        display: none;
    }
}
@media (max-height: 610px) {
    #patrimoine7 {
        display: none;
    }
}
/*************************************************/
/************** Responsives hacks ****************/
/*************************************************/
@media (max-width: 1900px) {
    .illus-un-et-demi-width {
        max-width: 24vw;
    }
    .illus-trois-quart-width {
        max-width: 26vw;
    }
    .illus-double-width {
        max-width: 30vw;
    }
    .illus.more-w {
        max-width: 33vw;
    }
    .illus.less-w {
        max-width: 25vw;
    }
    p {
        font-size: 1vw;
    }
    header .illus {
        max-width: 550px;
        width: 40vw;
    }
}
@media (max-width: 1400px) {
    header .illus {
        max-width: 300px;
        width: 30vw;
    }
    .illus-mg-deux {
        left: -30vw;
    }
}
@media (max-width: 1024px) {
    p {
        font-size: 16px;
    }
    header .illus {
        max-width: 300px;
        width: 30vw;
    }
    .illus-mg-deux {
        left: -20vw;
    }
    .mt-15 {
        margin-top: 2em;
    }
}
@media (max-width: 981px) {
    .illus-mg-deux {
        left: -30vw;
    }
    .vh-100, .min-vh-100, .h-100 {
        height: auto;
        min-height: 0px !important;
    }
    .verticaly-center {
        position: relative;
        transform: none;
    }
    .scroll-down-abs {
        display: none;
    }
    .illus-bd-mobile {
        left: auto;
        top: auto;
        bottom: 0;
        right: -5vw;
    }
    .illus-hg-mobile {
        bottom: auto;
        right: auto;
        left: -5vw;
        top: 0;
    }
    .illus {
        max-width: 17vw;
    }
    .desc_imperatrice {
        padding-top: 10vw !important;
    }
}
.grecaptcha-badge {
    visibility: hidden;
 }
@media (max-width: 600px) {
    header .illus {
        max-width: 300px;
    }
    .date {
        font-size: 5rem;
    }
    .illus-hg {
        left: -8vw;
        top: -2vh;
    }
    .illus-bd, .illus-on-bd {
        right: -8vw;
        bottom: 0;
    }
    .back_home {
        width: 100%;
        display: inline-block;
        background: -moz-linear-gradient(top,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
        background: -webkit-linear-gradient(top,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
        background: linear-gradient(to bottom,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 100%);
        filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#00ffffff',GradientType=0);
        position: relative;
        z-index: 77777;
    }
    .illus {
        position: absolute;
        max-width: 30vw;
    }
    body {
        font-size: 1rem;
    }
    .burger, .close_btn {
        right: 10px;
    }
    .btls {
        height: 90% !important;
    }
    .btl {
        background-size: cover;
        width: 30vh;
        bottom: 0%;
    }
    .titre-mobile {
        bottom: 0.5vh;
        z-index: 778;
        width: 100vw;
        height: 10vh;
    }
    .titre-mobile h1 {
        font-size: 8vw;
        z-index: 778;
    }
    .scene>.h-100 {
        height: 85% !important;
    }
    .logo.logo_home {
        display: none
    }
    .img-art {
        margin-top: 1rem;
        margin-left: 0;
    }
    .mt-15 {
        margin-top: .5em;
    }
    .art-du-champagne .illus {
        display: none;
    }
    .img-imperatrice {
        margin: 0 auto;
        display: block;
    }
    .prev {
        left: 5px;
    }
    .next {
        right: 5px;
    }
    .icones img {
        max-width: 45px;
        max-height: 45px;
    }
    .desc_imperatrice {
        padding-top: 5vw !important;
    }
    .bloc_jonkoping h1 {
        font-size: 2rem;
    }
    .bloc_jonkoping h1 small {
        font-size: 1.6rem;
    }
}
@media (min-width: 2000px) {
    .art-du-champagne .illus {
        display: none;
    }
    .illus-bd, .illus-bg {
        bottom: 18vh;
    }

}
@media (min-width: 1900px) {
    nav {
        font-size: 1.5vw;
    }
}
#page {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}

#contenu {
    flex: 1;
}

.mb-n5vh {
    margin-bottom: -5vh;
}

footer div ul, footer div a, footer div div {
    width: 140px;
}
@media (max-width: 991px) {
    .mt-special {
        margin: 0 0 -5vh 0 !important;
    }
    .jonko .row .mb-15 {
        margin-bottom: 0;
    }
    .jonko-1 {
        top: 25vh;
        max-width: 40vw !important;
    }
    .jonko-2 {
        max-width: 50vw !important;
        bottom: 0;
    }
    .ifx {
        min-height: 550px;
    }
}
.g-recaptcha {
    visibility: hidden;
    height: 0;
}
